<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton :showBtn="false" :showAddNew="false" title="ছক-২ : স্থায়ী সম্পদ বিবরণী"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
          <label for="colFormLabel" >Select FD6</label>
          <v-select
            placeholder="Select FD6"
            :options="projects"
            label="name"
            :reduce="name => name.id"
            v-model="projectId"
            @option:selected="onSelectProject"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
          <label for="colFormLabel">FD6 Budget</label>
          <v-select
            placeholder="Select FD6 Budget"
            v-model="budgetMasterId"
            :options="donors"
            label="name"
            :reduce="name => name.id"
            @option:selected="onSelectBudget"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
          <label for="colFormLabel">FD6 Budget Breakdown</label>
          <v-select
              placeholder="Select Budget Breakdown"
              v-model="budgetBreakDown"
              :options="budgetBreakDowns"
              label="name"
              :reduce="name => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 mt-2">
          <label for="colFormLabel"> From </label>
          <input
              v-model="startDate"
              type="date"
              class="form-control date-mask"
              placeholder="From"
          >
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 mt-2">
          <label for="colFormLabel"> To </label>
          <input
              v-model="endDate"
              type="date"
              class="form-control date-mask"
              placeholder="From"
          >
        </div>
        <div class="clearfix"></div>
        <div class="col-2 col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
          <button
              style="min-width: 64px;"
              @click="exportReport()"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
          >Export
          </button>
        </div>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import Loader from '@/components/atom/LoaderComponent'
import {computed, inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import handleFD6s from "@/services/modules/procurement/fd6";

const {fetchFD6s, fetchFD6Donors, fetchFD6BudgetBreakDowns} = handleFD6s();

const $route = useRoute();
const showError = inject('showError');
const query = `?company_id=${$route.params.companyId}`;
const token = localStorage.getItem('token');
const projects = ref([]);
const donors = ref([]);
const startDate = ref(null);
const endDate = ref(null);
const projectId = ref(null);
const budgetMasterId = ref(null);
const isLoading = ref(false);
const budgetBreakDown = ref(null);
const budgetBreakDowns = ref([]);
const onSelectProject = () => {
  isLoading.value = true;
  fetchFD6Donors(projectId.value, query)
    .then( res => {
      if(res.status) {
        budgetMasterId.value = null;
        budgetBreakDown.value = null;
        budgetBreakDowns.value = [];
        donors.value = res.data;
      } else {
        showError(res.message)
      }
    })
    .catch( err => {
      showError(err.response.message);
    })
    .finally( () => isLoading.value = false)
}
const onSelectBudget = () => {
  isLoading.value = true;
  budgetBreakDowns.value = [];
  fetchFD6BudgetBreakDowns(budgetMasterId.value, query)
      .then( res => {
        if(res.status) {
          budgetBreakDown.value = null;
          budgetBreakDowns.value = res.data;
        } else {
          showError(res.message)
        }
      })
      .catch( err => {
        showError(err.response.message);
      })
      .finally( () => isLoading.value = false)
}

const exportReport = () => {
  if (projectId.value === null) {
    showError('Please select a fd6');
    return;
  }
  let objQuery = {
    company_id: $route.params.companyId,
    budget_master_id: budgetMasterId.value ?? '',
    breakdown: budgetBreakDown.value ?? '',
    start_date: startDate.value,
    end_date: endDate.value,
    _token: token,
    report_type: "ngoab-report-annexure2",
    report_title: "ছক-২ : স্থায়ী সম্পদ বিবরণী"
  }
  let params = Object.entries(objQuery).map(([key, val]) => `${key}=${val}`).join('&');
  let url = `${process.env.VUE_APP_BASE_URL}/export/ngoab-report-annexure2/${projectId.value}?${params}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

onMounted( () => {
  isLoading.value = true;
  Promise.all([
    fetchFD6s(query).then( res => {
      if(res.status) {
        projects.value = res.data;
      } else {
        showError(res.message)
      }
    }),
  ])
    .then ( () => isLoading.value = false)
    .catch( (err) => {
      isLoading.value = false
      showError( err.response.message )
    })
    .finally( () => isLoading.value = false)
})

</script>
